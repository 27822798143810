import { useState } from "react";
import { setPageInfo, useEffect } from "core/react-utils";
import { observer } from "mobx-react-lite";
import Page from "pages/page";
import Spinner from "components/spinner";
import { Grid, Icon, Step } from "semantic-ui-react";
import { AppStore, ProjectStore, SettingsStore } from "stores";
import "./setup.scss";
import PaymentStep from "./payment-step";
import { AccountSetupStep, AccountSetupStepType } from "../../models/account-setup";
import ProjectForm from "../../models/forms/project-form";
import EnvironmentForm from "../../models/forms/environment-form";
import FinalStep from "./final-step";
import { TestFramework } from "../../models/project";
import UploadStep from "./upload-step";
import ConnectStep from "./connect-step";
import getRandomName from "namesgenerator";

const SetupPage = observer(() => {
  setPageInfo("setup", "SetupPage");

  const [setupStep, setSetupStep] = useState<AccountSetupStepType>(null);
  const [setupSteps, setSetupSteps] = useState<Array<AccountSetupStepType>>([]);
  const [initialized, setInitialized] = useState(false);

  function toTypes(types: Array<AccountSetupStep>) {
    return types.map(({ type }) => type);
  }

  async function loadSetupSteps() {
    setSetupSteps(toTypes(await SettingsStore.loadAccountSetupSteps()));
  }

  const next = async () => {
    await loadSetupSteps();
  };

  useEffect(async () => {
    handleStepSelection();
  }, [setupSteps]);

  useEffect(async () => {
    setInitialized(false);
    await SettingsStore.loadAuthorizations();
    await AppStore.loadAvailablePlans();

    await loadSetupSteps();

    const projects = await ProjectStore.loadAll();
    if (projects.length === 0) {
      const randomName = getRandomName();
      const projectForm = new ProjectForm();
      projectForm.testingFramework = TestFramework.Other;
      projectForm.key = randomName.replace("_", "-").toLowerCase();
      projectForm.name = randomName;
      await ProjectStore.save(projectForm);
    }

    const environments = await SettingsStore.loadEnvironments();
    if (environments.length === 0) {
      const environmentForm = new EnvironmentForm();
      environmentForm.key = "development";
      environmentForm.name = "Development";
      await SettingsStore.saveEnvironment(environmentForm);
    }

    setInitialized(true);
  }, [AppStore.selectedAccountId]);

  function handleStepSelection() {
    let selectedSetupStep: AccountSetupStepType;

    if (paymentStepComplete() && uploadStepComplete() && connectStepComplete()) {
      selectedSetupStep = AccountSetupStepType.Final;
    } else if (setupSteps.includes(AccountSetupStepType.Upload)) {
      selectedSetupStep = AccountSetupStepType.Payment;
    } else if (connectStepComplete()) {
      selectedSetupStep = AccountSetupStepType.Upload;
    } else {
      selectedSetupStep = AccountSetupStepType.Connect;
    }

    setSetupStep(selectedSetupStep);
  }

  function connectStepComplete() {
    return setupSteps.includes(AccountSetupStepType.Connect);
  }

  function paymentStepComplete() {
    return AppStore.account?.currentAccountPlan != null;
  }

  function uploadStepComplete() {
    return setupSteps.includes(AccountSetupStepType.Upload)
  }

  if (!initialized) {
    return (
      <Page name="setup" title="Setup" noHeader titleSize="none">
        <Spinner size={22} />
      </Page>
    );
  }

  return (
    <Page name="setup" title="Setup Your AI Test Failure Analysis Delivered to Slack" noHeader={true}>
      <Grid columns={2}>
        <Grid.Column>
          <Step.Group fluid vertical>

            <Step active={setupStep == AccountSetupStepType.Connect}
                  completed={setupStep != AccountSetupStepType.Connect && connectStepComplete()}>
              <Icon name={"slack hash"} />
              <Step.Content>
                <Step.Title>Invite Testery AI Assistant to Slack</Step.Title>
                <Step.Description>The Testery AI assistant will deliver insights about your tests to
                  Slack.</Step.Description>
              </Step.Content>
            </Step>

            <Step active={setupStep == AccountSetupStepType.Upload}
                  completed={setupStep != AccountSetupStepType.Upload && setupSteps.includes(AccountSetupStepType.Upload)}>
              <Icon name={"cloud upload"} />
              <Step.Content>
                <Step.Title>Upload Test Run for AI Analysis</Step.Title>
                <Step.Description>Send Testery AI a test result file to analyze or use one of our
                  samples.</Step.Description>
              </Step.Content>
            </Step>

            <Step active={setupStep == AccountSetupStepType.Payment}
                  completed={setupStep != AccountSetupStepType.Payment && paymentStepComplete()}>
              <Icon name="credit card" />
              <Step.Content>
                <Step.Title>Setup Your Credit Card to Get Started</Step.Title>
                <Step.Description>Not sure what plan you need?  With Testery Cloud Growth, only pay for what you need, your <strong>first 10 hours every month are on us</strong>.</Step.Description>
              </Step.Content>
            </Step>

            <Step active={setupStep == AccountSetupStepType.Final}>
              <Icon name={"flag checkered"} />
              <Step.Content>
                <Step.Title>View Your Test Failure Analysis</Step.Title>
                <Step.Description>See why your tests are failing and recommendations to get back to
                  green.</Step.Description>
              </Step.Content>
            </Step>

          </Step.Group>
        </Grid.Column>
        <Grid.Column>
          {setupStep == AccountSetupStepType.Connect && <ConnectStep next={next} />}
          {setupStep == AccountSetupStepType.Upload &&
            <UploadStep next={next} project={ProjectStore.active[0]} environment={SettingsStore.environments[0]} />}
          {setupStep == AccountSetupStepType.Payment && <PaymentStep next={next} />}
          {setupStep == AccountSetupStepType.Final && <FinalStep />}
        </Grid.Column>
      </Grid>
    </Page>
  );
});

export default SetupPage;
